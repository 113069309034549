<template>
  <div id="RecoveryTemplateWrapper">
    <div class="RecoveryTemplate">
      <span class="TemplateHeader">
        <template v-if="Success">
          <i class="bx bxs-check-circle status-success"></i>
        </template>
        <template v-else>
          <i class="bx bxs-x-circle status-fail"></i>
        </template>
        <span class="HeaderText" v-html="Header"></span>
      </span>
      <div class="TemplateContent">
        <GradientButton
          :ButtonText="ButtonText"
          :ButtonLink="RedirectLink"
          :NeedIcon="false"
          :NotUseRouter="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import GradientButton from "@/components/Landing/ButtonInsideGradient.vue";

export default {
  data() {
    return {
      Header: "",
      ButtonText: "На главную",
      RedirectLink: "",
      Success: false,
    };
  },
  components: { GradientButton },
  created() {
    let route = useRouter();

    if (route.currentRoute.value.fullPath == "/auth/reset/mailsent") {
      this.Success = true;
      this.Header =
        "Проверьте почту!<br/> Сообщение с ссылкой на восстановление отправлено на вашу почту.";
      this.ButtonText = "На главную";
      this.RedirectLink = "/";
    } else if (route.currentRoute.value.fullPath == "/auth/password-changed") {
      this.Success = true;
      this.Header = "Пароль успешно изменён!";
      this.ButtonText = "Вернуться на страницу авторизации";
      this.RedirectLink = "/auth/signup-login#pills-register";
    } else if (route.currentRoute.value.fullPath == "/auth/link-expired") {
      this.Header = "Ссылка больше не действительна";
      this.ButtonText = "Вернуться на страницу авторизации";
      this.RedirectLink = "/auth/signup-login#pills-register";
    } else if (route.currentRoute.value.fullPath == "/auth/account-activated") {
      this.Success = true;
      this.Header = "Аккаунт успешно активирован!";
      this.ButtonText = "На главную";
      this.RedirectLink = "/";
    }
  },
  methods: {},
};
</script>

<style scoped>
#RecoveryTemplateWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;

  width: 100%;
  height: 100vh;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  ) !important;
  background-position: 50% 50% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: auto 120% !important;
}

.RecoveryTemplate {
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 120px auto 0% auto;

  width: 100%;
  max-width: 30rem;

  height: fit-content;
}

.RecoveryTemplate > * {
  position: relative;

  width: 100%;
  height: fit-content;
}

.TemplateHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TemplateContent {
  position: relative;
  display: flex;

  margin-top: 30px;

  width: 100%;
  height: fit-content;
}

.TemplateHeader > * {
  margin-left: auto;
  margin-right: auto;
}
.TemplateHeader > i {
  position: relative;

  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-content: center;

  font-size: 35px;
}

.status-success {
  color: #5edc4c;
}
.status-fail {
  color: #dc4c4c;
}

.HeaderText {
  position: relative;

  text-align: center;

  margin-top: 10px;

  color: #fff;

  font-size: 1.2rem;
  line-height: 120%;
  font-weight: 600;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .HeaderText {
    font-size: 1rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .HeaderText {
    font-size: 1rem;
  }
}
</style>
